import React from "react"
import { graphql, Link } from 'gatsby'
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"
// import Img from "gatsby-image"
// import { StaticImage } from 'gatsby-plugin-image'

export default function Home({ data }) {
  
  return (
    <Layout>
      <section className="homeModule">
        <div className={styles.header}>
          <div className={styles.headerContent}>
              <h1>Welcome to Rival Solutions.</h1>
              <h3>We are committed to help employees feel inspired and valued.</h3>
              
              <Link className={styles.btn} to="/contact">Contact Us</Link>
          </div>
          <img src="/Dashboard.png" alt="Dashboard image"  />
          {/* <Img fluid={data.file.childImageSharp.fluid} /> */}
          {/* <StaticImage src= "../images/Dashboard.png" width={100} /> */}
        </div>
        <div className={styles.verifyBadge}>
          <img src="/eVerify.png" alt="E-Verify image" style={{maxWidth: '90%'}} />
          <h3>We are an E-Verified employer</h3>
        </div>
        <div className={styles.vision}>
        <img src="/mission.png" alt="Mission and Vision image"  />
        <div>
          <h2>Vision & Mission</h2>
          <h3>Rival Solutions Incorporated is committed to helping employees feel inspired and valued. We provide comprehensive employee benefits programs to support their well-being.</h3>
        </div>    
      </div>
      </section>
    </Layout>
    
  )
}


// export const query = graphql
// `
// query Banner {
//   file(relativePath: {eq: "Dashboard.png"}) {
//     childImageSharp {
//       fluid {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }

// `
